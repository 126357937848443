import { render, staticRenderFns } from "./highQualityCompany.vue?vue&type=template&id=6f048fcd&scoped=true&"
import script from "./highQualityCompany.vue?vue&type=script&lang=js&"
export * from "./highQualityCompany.vue?vue&type=script&lang=js&"
import style0 from "./highQualityCompany.vue?vue&type=style&index=0&id=6f048fcd&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f048fcd",
  null
  
)

export default component.exports